<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col
					:order="$vuetify.breakpoint.xsOnly ? 'last' : null"
					cols="12"
					sm="7"
				>
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
				<v-col cols="12" sm="5">
					<social :meta="meta" />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<p class="blockquote">
						Autism Spectrum Disorder is a condition that can affect
						a person’s ability to interact with the world around
						them. There are varying levels of severity and abilities
						on the Autism Spectrum. Comprehensive assessment by a
						skilled professional is important in accessing support,
						guiding intervention and strategies to support the child
						or young person.
					</p>
					<p>
						Both Alicia and Stephanie are qualified and trained to
						complete assessments for Autism Spectrum Disorder. They
						follow the gold standard approach to assessment which
						includes:
					</p>
					<ul>
						<li>
							Autism diagnostic observation schedule - second
							edition (with child).
						</li>
						<li>
							Autism Diagnostic Interview - revised (with
							parents/carers).
						</li>
						<li>
							Interview with classroom teachers or relevant
							people.
						</li>
						<li>School observation when required.</li>
					</ul>
					<p>
						If your child is 13 years old and under a referral from
						a paediatrician or psychiatrist is required to access
						the rebate under the ‘Helping Children with Autism
						Scheme’.
					</p>
				</v-col>
				<v-col cols="12">
					<cta
						:color="$vuetify.theme.themes.light.autism"
						icon="$vuetify.icons.autism"
					/>
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import Cta from '@/components/cta/Cta';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
export default {
	name: 'AutismSpectrumDisorder',
	components: {
		Breadcrumbs,
		Cta,
		HeroImage,
		Social
	},
	mixins: [_MxAppViewClass, _MxMeta],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: 'services',
					disabled: false,
					to: '/services'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			hero: {
				alt: this.$route.name,
				heading: this.$route.name,
				imgSmall: 'hero-07-small',
				imgLarge: 'hero-07-large'
			},
			meta: {
				description:
					'Autism Neuropsychological Assessment for your child or adolescent from Sydney Kids Neuropsychology.',
				title: 'Autism Spectrum Disorder'
			}
		};
	}
};
</script>
